import styled from "styled-components";

const DayBreakWrapper = styled.div`
  width: calc(100% + 64px);
  margin-left: -32px;
  background-color: ${(props) => props.theme.colors.border};
  padding: 24px 0;
  text-align: center;
  div {
    font-size: 16px !important;
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

const I = styled.i`
  color: #7271c7;
  font-size: 48px;
  margin-bottom: 24px;
  display: block;
`;

const DayBreak = (props) => {
  return (
    <DayBreakWrapper {...props}>
      <I className="ri-calendar-fill"></I>
      {props.children}
    </DayBreakWrapper>
  );
};

export default DayBreak;
