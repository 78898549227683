import styled from "styled-components";

const Wrapper = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.border};
  border-right: 1px solid ${(props) => props.theme.colors.border};
  max-width: 700px;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
  padding: 32px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default Wrapper;
