const theme = {
  colors: {
    bg: "#3e3e3e",
    border: "#484848",
    textPrimary: "#eaeaea",
    textSecondary: "#a2a2a2",
    accent: "#5281ff",
  },
};

const lightTheme = {
  colors: {
    bg: "#FFFFFF",
    border: "#EDEDED",
    textPrimary: "#2A2F3C",
    textSecondary: "#92969F",
    accent: "#5281ff",
  },
};

const getTheme = (isDark) => {
  return isDark ? theme : lightTheme;
};

export default getTheme;
