import React from "react";
import styled from "styled-components";
import DayBreak from "./DayBreak";
import Break from "./Break";

const TimeWrapper = styled.div`
  padding: 8px 0;
`;

const Timestamp = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 12px;
  padding-bottom: 4px;
`;

const Task = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 16px;
  font-weight: bold;
  white-space: pre-line;
  position: relative;

  ::before {
    content: " ";
    display: block;
    left: -16px;
    top: 8px;
    width: 4px;
    height: 4px;
    background: ${(props) => props.wC || props.theme.colors.textPrimary};
    position: absolute;
  }
`;

const NoteWrapper = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.textSecondary};
  padding-left: 12px;
  margin-left: 12px;
`;

const Note = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 14px;
  white-space: pre-line;
  margin-top: 8px;
`;

const Wrappers = {
  Break,
  DayBreak,
  Task: TimeWrapper,
};

export default function Time({
  timeString,
  taskString,
  taskNotes = [],
  workspaceColor,
}) {
  const type = taskString.startsWith("--")
    ? "Break"
    : taskString.startsWith("__")
    ? "DayBreak"
    : "Task";

  const Wrapper = Wrappers[type];
  return (
    <Wrapper>
      <Timestamp>{timeString}</Timestamp>
      {type === "Task" && <Task wC={workspaceColor}>{taskString}</Task>}
      <NoteWrapper>
        {taskNotes.map((note, index) => (
          <Note key={timeString + index}>{note}</Note>
        ))}
      </NoteWrapper>
    </Wrapper>
  );
}
