import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import getTheme from "./theme";
import Timeline from "./Timeline";
// import ThemeSwitch from "./Timeline/ThemeSwitch";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.bg};
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
  }
  * {
    box-sizing: border-box;
  }
`;

export default function App() {
  // const switchTheme = () => {};

  const theme = getTheme(true);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Timeline />
      {/* <ThemeSwitch onClick={switchTheme} /> */}
    </ThemeProvider>
  );
}
