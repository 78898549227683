import React, { useRef, useEffect, useState, useCallback } from "react";
import Time from "./Time";
import Wrapper from "./Wrapper";
import TimeEditor from "./TimeEditor";
import moment from "moment";
// import Clear from "./Clear";

export default function Timeline() {
  const editorRef = useRef(null);
  const [list, _setList] = useState([]);
  const listRef = useRef(list);
  const workspaceColors = {
    default: "#eaeaea",
    bb: "#4E7FFF",
    st: "#55C000",
  };

  const setList = (value) => {
    listRef.current = value;
    _setList(value);
  };

  const addTask = useCallback((task) => {
    const newList = [...listRef.current];
    if (task.startsWith(">")) {
      task = task.startsWith(">\n") ? task.substring(2) : task.substring(1);
      newList[newList.length - 1] = {
        ...newList[newList.length - 1],
        notes: [...(newList[newList.length - 1].notes || []), task],
      };
    } else {
      const wpEnd = task.indexOf(":");
      const workspace = wpEnd ? task.substring(0, 2) : "default";
      task = wpEnd ? task.substring(wpEnd + 1) : task;

      newList.push({
        task,
        workspace,
        time: Date.now(),
      });
    }

    localStorage.setItem("list", JSON.stringify(newList));
    setList(newList);
  }, []);

  const onKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
        const task = editorRef.current.value;

        if (task.trim().length > 0) {
          addTask(task);
          editorRef.current.value = "";
        }
      }

      if (e.ctrlKey || e.metaKey) {
        return;
      }

      editorRef.current.focus();
      editorRef.current.scrollIntoView();
    },

    [addTask]
  );

  useEffect(() => {
    setList(JSON.parse(localStorage.getItem("list")) || []);

    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress, addTask]);

  useEffect(() => {
    editorRef.current.scrollIntoView();
  }, [list]);

  // const clear = () => {
  //   const con = window.confirm("Clear timeline?");
  //   if (con === true) {
  //     setList([]);
  //     localStorage.clear();
  //   }
  // };

  return (
    <Wrapper>
      {list.map((t, i) => (
        <Time
          timeString={
            moment(t.time).format("HH:mm dddd D MMMM, YYYY") +
            (i < list.length - 1
              ? ` - ${moment
                  .utc(moment(list[i + 1].time).diff(moment(t.time)))
                  .format("HH:mm")}`
              : "")
          }
          key={i}
          workspaceColor={
            workspaceColors[t.workspace] || workspaceColors.default
          }
          taskString={t.task}
          taskNotes={t.notes}
        />
      ))}
      <TimeEditor key={list.length} ref={editorRef} autoFocus />

      {/* <Clear onClick={clear} /> */}
    </Wrapper>
  );
}
