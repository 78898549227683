import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

const TimeEditor = styled(TextareaAutosize)`
  background: transparent;
  border: none;
  width: 100%;
  height: 64px;
  color: ${(props) => props.theme.colors.accent};
  resize: none;
  font-size: 16px;
  padding: 12px 0;
  margin-bottom: 46vh;
  caret-color: ${(props) => props.theme.colors.accent};
  font-family: "Rubik", sans-serif !important;
  :focus {
    border: none;
    outline: none;
  }
`;

export default TimeEditor;
