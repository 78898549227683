import styled from "styled-components";

const DayBreak = styled.div`
  width: calc(100% + 64px);
  margin-left: -32px;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  padding: 12px 0;
  text-align: center;
  div {
    font-size: 16px !important;
  }
`;

export default DayBreak;
